// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("E:\\Pacha\\pacha-website\\src\\pages\\404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-character-creator-tsx": () => import("E:\\Pacha\\pacha-website\\src\\pages\\character-creator.tsx" /* webpackChunkName: "component---src-pages-character-creator-tsx" */),
  "component---src-pages-festival-tsx": () => import("E:\\Pacha\\pacha-website\\src\\pages\\festival.tsx" /* webpackChunkName: "component---src-pages-festival-tsx" */),
  "component---src-pages-index-tsx": () => import("E:\\Pacha\\pacha-website\\src\\pages\\index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-orders-tsx": () => import("E:\\Pacha\\pacha-website\\src\\pages\\orders.tsx" /* webpackChunkName: "component---src-pages-orders-tsx" */),
  "component---src-pages-preorder-tsx": () => import("E:\\Pacha\\pacha-website\\src\\pages\\preorder.tsx" /* webpackChunkName: "component---src-pages-preorder-tsx" */),
  "component---src-pages-signup-tsx": () => import("E:\\Pacha\\pacha-website\\src\\pages\\signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */)
}

